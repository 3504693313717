* {
	box-sizing: border-box;
}
body {
	direction: rtl;
	margin: 15px;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #f0c731;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
label {
	text-align: right;
	display: block;
	margin-bottom: 10px;
	font-weight: bold;
}
input {
	width: 100%;
	padding: 15px;
	margin-bottom: 20px;
	text-align: right;
	border: 1px solid #807272;
	font-size: 16px;
	border-radius: 5px;
}
input[type="button"] {
	background: #406f43;
	border: 0px;
	height: 50px;
	color: #fff;
	text-align: center;
	font-size: 16px;
	font-weight: bold;
	margin-top: 15px;
}
.container {
	text-align: center;
}
.logo {
	width: 60px;
	height: auto;
}
.periodsHolder {
	margin-top: 20px;
}
.periods {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	font-weight: bold;
}
.period {
	display: inline-block;
	text-align: center;
	margin-bottom: 10px;
	padding: 15px;
	margin-left: 2px;
	margin-right: 5px;
	width: calc(50% - 10px);
	border-radius: 7px;
	cursor: pointer;
	color: green;
}
.period_quantity {
	margin-top: 10px;
	font-size: 12px;
	color: #1a1e28;
	font-weight: normal;
}
.card {
	margin: 10;
	text-align: center;
	background: #f2f2f2;
	padding: 15px;
	border-radius: 15px;
}
.message {
	color: red;
	font-weight: bold;
}
.card-name {
	margin: 5px;
	font-size: 18px;
}
.book_number {
	background: #406f43;
	padding: 10px;
	border-radius: 5px;
	color: #f0c731;
	font-weight: bold;
}
.book_number h2 {
	margin: 0;
	margin-top: 5px;
}

.screenshot {
	position: absolute;
	margin-top: 30px;
	text-align: center;
	width: 85%;
	font-weight: bold;
	font-size: 20px;
}
.location {
	margin: 5px;
}
.date {
	font-weight: normal;
	color: #000;
	font-size: 14px;
}
.familyHolder {
	display: flex;
	flex-direction: column;
	margin-top: 15px;
	justify-content: space-around;
	align-items: center;
	width: 100%;
}
.familyYes {
	flex: 1;
	width: 100%;
	margin-left: 15px;
	margin-right: 15px;
	text-align: center;
	background: #fff;
	padding: 50px;
	color: green;
	margin-bottom: 15px;
	border-radius: 10px;
	font-size: 18px;
	font-weight: bold;
}
.familyNo {
	flex: 1;
	width: 100%;
	margin-left: 15px;
	margin-right: 15px;
	text-align: center;
	background: #fff;
	padding: 50px;
	color: green;
	margin-bottom: 15px;
	border-radius: 10px;
	font-size: 18px;
	font-weight: bold;
}
.margin-bottom {
	margin-bottom: 30px;
}
.answer {
	display: flex;
	flex-direction: row;
}
.yes {
	flex: 1;
	background: #fff;
	padding: 15px;
	margin-bottom: 10px;
	border-radius: 5px;
	margin-left: 5px;
	font-weight: bold;
}
.no {
	flex: 1;
	background: #fff;
	padding: 15px;
	margin-bottom: 10px;
	border-radius: 5px;
	margin-right: 5px;
	font-weight: bold;
}
.backBtn {
	color: #406f43;
	display: inline;
	padding: 10px;
	padding-left: 20px;
	padding-right: 20px;
	border-radius: 10px;
	font-weight: bold;
	position: absolute;
	top: 30px;
	left: 10px;
	cursor: pointer;
	font-size: 30px;
}
.hasKidHolder {
	margin-bottom: 15px;
}
.termContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 15px;
	flex-direction: column;
	font-weight: bold;
	font-size: 20px;
	text-align: center;
}
.accept {
	display: inline;
	background: #406f43;
	padding: 10px;
	padding-left: 20px;
	padding-right: 20px;
	border-radius: 10px;
	color: #fff;
	cursor: pointer;
}
